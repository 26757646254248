@import '../../../scss/utilities';

.full {
  &_padded {
    // add space above and below the ad's container
    // useful in places like the footer
    padding: $grid-gutter-width 0;
  }
  &_inArticle {
    margin-top: $ue-article-padding;
    margin-bottom: $ue-article-padding;
    @include media-breakpoint-up(sm) {
      margin-top: $ue-article-padding-sm;
      margin-bottom: $ue-article-padding-sm;
    }
    @include media-breakpoint-up(md) {
      margin-top: $ue-article-padding-md;
      margin-bottom: $ue-article-padding-md;
      margin-left: -$ue-article-padding-md;
      margin-right: -$ue-article-padding-md;
    }
  }
  &_inFeed {
    @include media-breakpoint-up(md) {
      padding-left: $grid-gutter-width*0.5;
      padding-right: $grid-gutter-width*0.5;
    }
  }
}

.full__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 90px;
  background-color: $ue-lighter-gray;
}
