$ue-black: #1f2021;
$ue-dark-gray: #373a3c;
$ue-medium-gray: #707070;
$ue-light-gray: #c4c4c4;
$ue-lighter-gray: #e3e3e3;
$ue-white: #ffffff;

// Category colors
$ue-life-primary: #009f5e;
$ue-parenting-primary: #42274b;
$ue-home-primary: #1e859f;
$ue-pets-primary: #778a74;
$ue-health-primary: #e44d50;
$ue-astrology-primary: #621300;
$ue-oddities-primary: #ffc036;

$category-colors: (
  'life': $ue-life-primary,
  'parenting': $ue-parenting-primary,
  'home': $ue-home-primary,
  'pets': $ue-pets-primary,
  'health': $ue-health-primary,
  'astrology': $ue-astrology-primary,
  'oddities': $ue-oddities-primary,
);

// These can be used to create dynamic styles:
// @each $category, $color in $category-colors {
//   &--#{$category} {
//     color: $color;
//   }
// }

// Shadow colors
$ue-shadow: rgba(0, 0, 0, 0.15);

// TODO: investigate faster loading strategies
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;700&display=swap');

$ue-brand-font: 'Rubik', sans-serif;
$ue-header-font: 'Playfair Display', serif;
$ue-subheader-font: 'Montserrat', sans-serif;
$ue-body-font: 'Source Serif Pro', serif;

// Font sizes
$ue-font-size-1: 9rem;
$ue-font-size-2: 6.2rem;
$ue-font-size-3: 5.2rem;
$ue-font-size-4: 4.3rem;
$ue-font-size-5: 3.6rem;
$ue-font-size-6: 3rem;
$ue-font-size-7: 2.5rem;
$ue-font-size-8: 2rem;
$ue-font-size-9: 1.7rem;
$ue-font-size-10: 1.4rem;
$ue-font-size-11: 1.2rem;

// Icons
@import '~open-iconic/font/css/open-iconic-bootstrap.scss';

// Component Dimensions
$ue-top-ad-height-desktop: 190px; // Max ad unit height
$ue-top-ad-height-mobile: 140px; // Max ad unit height
$ue-header-height: 60px;
$ue-sidebar-width: 300px;

// Component Helpers
$ue-dashed-border: 1px dashed $ue-light-gray;

// Article Padding
$ue-article-padding: 15px; // $grid-gutter-width/2
$ue-article-padding-sm: 30px; // $grid-gutter-width
$ue-article-padding-md: 60px; // $grid-gutter-width * 2
